import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const fetchTechDocFullTextSearchPageData = (pageSize, sorted, cursor, isNextFromPivot, searchOption, filtered, clicked = false) => (dispatch) => {

    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_FULL_TEXT_SEARCH_GET,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_FULL_TEXT_SEARCH_GET,
        payload: {
            lastCommand: Date.now(),
            pageSize: pageSize,
            sorted: sorted,
            cursor,
            isNextFromPivot,
            searchOption,
            filtered: filtered,
            clicked
        },
    });
};

export const fetchTechDocLastEditor = () => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
        payload: {lastCommand: Date.now()},
    });
};
